<template>
  <v-card>
    <v-tabs v-model="tab" background-color="transparent" color="basil" grow>
      <v-tab v-for="item in items" :key="item">
        {{ item }}
      </v-tab>
    </v-tabs>
    <v-tabs-items v-model="tab">
      <v-tab-item>
        <v-card-title>
          <v-spacer></v-spacer>
          <v-text-field class="searchStyle" v-model="search" append-icon="mdi-magnify" label="جست و جو" single-line hide-details></v-text-field>
        </v-card-title>
        <v-data-table :headers="headers" :items="QualifyLevel" :search="search" style="margin: 20px;">
          <template v-slot:item.operations="{ item }">
            <v-row>
              <v-btn color="warning" style="min-width: 50px !important;" @click="getItemQualifyLevel(item.id)">ویرایش</v-btn>
            </v-row>
          </template>
        </v-data-table>
      </v-tab-item>
      <v-tab-item>
        <v-card-title>
          <v-row style="margin-top:20px" >
            <v-col>
              <span>درصد پیشفرض ریفرال</span>
            </v-col>
            <v-col>
              <input v-model="referralSharePercentage" disabled class="Input">
            </v-col>
            <v-col v-if="!referralSharePercentageStatus">
              <v-btn class="whiteButton" @click="referralSharePercentageStatus = true">تغییر</v-btn>
            </v-col>
            <v-col v-if="referralSharePercentageStatus">
              <span>مقدار جدید : </span>
            </v-col>
            <v-col v-if="referralSharePercentageStatus">
              <input v-model="referralSharePercentageUpdate" class="Input">
            </v-col>
            <v-col v-if="referralSharePercentageStatus">
              <v-btn class="whiteButton" @click="updateDefaultReferral()">به روز رسانی</v-btn>
            </v-col>
          </v-row>
          <v-spacer></v-spacer>
          <v-text-field class="searchStyle" v-model="search" append-icon="mdi-magnify" label="جست و جو" single-line hide-details></v-text-field>
          <v-btn style="margin:15px 10px 0 0;" color="success" @click="showUpdate = !showUpdate">ویرایش</v-btn>
        </v-card-title>
        <v-data-table :headers="FinanceHeaders" :items="FinancialLevel" :search="search">
        </v-data-table>
      </v-tab-item>
    </v-tabs-items>
    <Popup :show.sync="show" width="700px">
      <template slot="body">
        <v-toolbar color="primary" dark class="rtl">
          ویرایش سطح کاربری
        </v-toolbar>
        <v-card-text class="rtl">
          <slot name="body"></slot>
          <div class="row" style="margin-top:20px">
            <div class="col-5" style="margin-right: 30px">عنوان</div>
            <input v-model="title" class="form-control col-5 panelInput">
          </div>
          <div class="row" style="margin-top:20px">
            <div class="col-5" style="margin-right: 30px">کد</div>
            <input v-model="code" class="form-control col-5 panelInput">
          </div>
          <div class="row" style="margin-top:20px">
            <div class="col-5" style="margin-right: 30px">بالاترین میزان واریز تومان در روز</div>
            <input v-model="maximumDepositPerDayInToman" class="form-control col-5 panelInput">
          </div>
          <div class="row" style="margin-top:20px">
            <div class="col-5" style="margin-right: 30px">بالاترین میزان برداشت در روز</div>
            <input v-model="maximumWithdrawalPerDayInToman" class="form-control col-5 panelInput">
          </div>
          <div class="row" style="margin-top:20px">
            <div class="col-5" style="margin-right: 30px">بالاترین میزان برداشت رمز ارز به تومان</div>
            <input v-model="maximumWithdrawalPerDayInNonTomanAssets" class="form-control col-5 panelInput">
          </div>
          <div style="margin-right: 40%;margin-top: 30px">
            <v-btn color="success" @click="updateQualifyLevel()">ویرایش</v-btn>
          </div>

          <hr style="border-top-color: white">

        </v-card-text>
      </template>
    </Popup>
    <Popup :show.sync="showUpdate" width="950">
      <template slot="body">
        <v-toolbar color="primary" dark class="rtl">
          ویرایش
        </v-toolbar>
        <v-card-text class="rtl" v-if="showUpdate">
          <slot name="body"></slot>
          <div v-for="(item,index) in updateDefaultDefinition" :key="index" class="row" style="margin-top: 20px;">
            <v-col cols="2" style="height: 65px;">
              <v-text-field v-model="item.title" label="عنوان" outlined></v-text-field>
            </v-col>
            <v-col cols="2" style="height: 65px;">
              <v-text-field v-model="item.code" label="کد" outlined></v-text-field>
            </v-col>
            <v-col cols="2" style="height: 65px;">
              <v-text-field v-model="item.exchangeFeePercent" label="درصد" outlined></v-text-field>
            </v-col>
            <v-col cols="4" style="height: 65px;">
              <v-text-field v-model="item.totalLastMonthTransactions" label="کل معاملات 1 ماه گذشته" outlined></v-text-field>
            </v-col>
            <v-col cols="2" style="height: 65px;">
              <v-btn color="red" style="margin-top:10px;color:white;" @click="removeRow(index)">حذف سطر</v-btn>
            </v-col>
          </div>
          <!-- <hr style="border-top-color: white"> -->
          <v-btn style="margin:40px 60px 0 0;" color="success" @click="UpdateDefaultDefinition()">ویرایش</v-btn>
          <v-btn style="margin:40px 20px 0 0;" color="success" @click="newRow()">اضافه کردن سطح جدید</v-btn>
        </v-card-text>
      </template>
    </Popup>
  </v-card>
</template>

<script>
import axiosApi from "@/axios";
import Vue from "vue";
import Popup from "@/components/Popup";

export default {
  name: "UserSettings",
  components: {
    Popup
  },
  data() {
    return {
      tab: null,
      referralSharePercentageStatus: false,
      referralSharePercentage: '',
      referralSharePercentageUpdate: '',
      items: ['لیست سطح کاربری', 'لیست سطح مالی'],
      updateDefaultDefinition: [],
      headers: [
        {
          text: 'عنوان ',
          align: 'start',
          sortable: false,
          value: 'title',
        },
        { text: 'تایید موبایل', value: 'mobileConfirmed' },
        { text: 'تایید عکس', value: 'imagesConfirmed' },
        { text: 'تایید ایمیل', value: 'emailConfirmed' },
        { text: 'تایید اطلاعات شخصی', value: 'personalInfoConfirmed' },
        { text: 'تایید بانکی', value: 'bankAccountConfirmed' },
        { text: 'تایید حضوری', value: 'faceToFaceConfirmed' },
        { text: 'تایید آدرس', value: 'addressInfoConfirmed' },
        { text: 'حداکثر واریز تومانی', value: 'maximumDepositPerDayInToman' },
        { text: 'حداکثر برداشت تومانی', value: 'maximumWithdrawalPerDayInToman' },
        { text: 'حداکثر برداشت رمز ارز به تومان', value: 'maximumWithdrawalPerDayInNonTomanAssets' },
        { text: 'عملیات', value: 'operations' },
      ],
      showUpdate: '',
      FinanceHeaders: [
        { text: 'عنوان', value: 'title' },
        { text: 'کد', value: 'code' },
        { text: 'درصد کارمزد', value: 'exchangeFeePercent' },
        { text: 'کل معاملات ماه گذشته', value: 'totalLastMonthTransactions' },
      ],
      QualifyLevel: [],
      id: '',
      UserName: '',
      title: '',
      FinancialLevel: [],
      code: '',
      maximumDepositPerDayInToman: '',
      maximumWithdrawalPerDayInToman: '',
      maximumWithdrawalPerDayInNonTomanAssets: '',
      show: '',
      search: '',
    };
  },
  mounted() {
    this.getQualifyLevel();
    this.getFinancialLevel();
    this.getDefaultDefinition();
  },
  methods: {
    removeRow(index) {
      this.updateDefaultDefinition.splice(index, 1);
    },
    updateDefaultReferral() {
      axiosApi().post('api/Manage/v1/ReferralShare/UpdateDefaultDefinition', {
        referralSharePercentage: this.referralSharePercentageUpdate
      }).then(() => {
        Vue.$toast.open({
          message: 'مقدار پیشفرض با موفقیت تغییر یافت',
          type: 'success',
          position: 'top-right'
        });
        this.referralSharePercentageStatus = false;
        this.getDefaultDefinition();
      });
    },
    newRow() {
      this.updateDefaultDefinition.push({ "title": "", "code": "", "exchangeFeePercent": '', "totalLastMonthTransactions": '' });
      console.log(this.updateDefaultDefinition);
    },
    getQualifyLevel() {
      axiosApi().post('/api/Manage/v1/QualifyLevel/GetList', {})
        .then(({ data }) => {
          if (data['isSuccess'] === true)
            this.QualifyLevel = data.data;
        });
    },
    updateQualifyLevel() {
      axiosApi().post('/api/Manage/v1/QualifyLevel/Update', {
        id: this.id,
        title: this.title,
        code: this.code,
        maximumDepositPerDayInToman: this.maximumDepositPerDayInToman,
        maximumWithdrawalPerDayInToman: this.maximumWithdrawalPerDayInToman,
        maximumWithdrawalPerDayInNonTomanAssets: this.maximumWithdrawalPerDayInNonTomanAssets
      }).then(({ data }) => {
        if (data['isSuccess']) {
          this.getQualifyLevel();
          Vue.$toast.open({
            message: 'تغییرات با موفقیت انجام گردید ',
            type: 'success',
            position: 'top-right'
          });
        }
      });
    },
    getItemQualifyLevel(id) {
      this.show = true;
      this.id = id;
      axiosApi().post('/api/Manage/v1/QualifyLevel/GetList', {
        id: id
      })
        .then(({ data }) => {
          if (data['isSuccess'] === true)
            this.title = data.data[0].title;
          this.code = data.data[0].code;
          this.maximumDepositPerDayInToman = data.data[0].maximumDepositPerDayInToman;
          this.maximumWithdrawalPerDayInToman = data.data[0].maximumWithdrawalPerDayInToman;
          this.maximumWithdrawalPerDayInNonTomanAssets = data.data[0].maximumWithdrawalPerDayInNonTomanAssets;
        });
    },
    getFinancialLevel() {
      axiosApi().post('api/Manage/v1/FinancialLevel/GetDefaultFinancialLevel')
        .then(({ data }) => {
          if (data['isSuccess'] === true)
            this.FinancialLevel = data.data.items;
          this.updateDefaultDefinition = data.data.items;
        });
    },
    getDefaultDefinition() {
      axiosApi().post('api/Manage/v1/ReferralShare/GetDefaultDefinition')
          .then((data) => {
            this.referralSharePercentage = data.data.data.referralSharePercentage;
          });
    },
    UpdateDefaultDefinition() {
      axiosApi().post('api/Manage/v1/FinancialLevel/UpdateDefaultDefinition', {
        items: this.updateDefaultDefinition
      }).then(() => {
        this.getFinancialLevel();
        this.showUpdate = false;
      });
    }
  }
};
</script>

<style scoped>

</style>
